/*Responsive menu and most styling taken from pure.css -- https://purecss.io/layouts/side-menu/ */

body {
    --menu-width: 275px;
    --anti-menu-width: -275px;
}

.App-header {
    font-family: 'Pacifico', cursive;
    color: #72583C;
    font-size: 2em;
    text-align: center;
    background-color: #DEDDDE;
    padding: 5px 0px;
}

li {
    font-family: 'Nunito Sans', sans-serif;
}

#map-container{
    width: 100%;
}


body {
    color: #777;
}

.pure-img-responsive {
    max-width: 100%;
    height: auto;
}

.pure-button {
    margin: 5px;
}

input {
    margin: 5px;
    border: white 2px solid;
    
}

/*
Add transition to containers so they can push in and out.
*/
#layout,
#menu,
.menu-link {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

/*
This is the parent `<div>` that contains the menu and the content area.
*/
#layout {
    position: relative;
    left: 0;
    padding-left: 0;
}
#layout.active #menu {
    left: var(--menu-width);
    width: var(--menu-width);
}

#layout.active .menu-link {
    left: var(--menu-width);
}

/*
The `#menu` `<div>` is the parent `<div>` that contains the `.pure-menu` that
appears on the left side of the page.
*/

#menu {
    margin-left: var(--anti-menu-width); /* "#menu" width */
    width: var(--menu-width);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; /* so the menu or its navicon stays above all content */
    background: #72583C;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

    #menu li {
        color: white;
        border: none;
        padding: 0.6em 0 0.6em 0.6em;
    }

    /*
    Remove all background/borders, since we are applying them to #menu.
    */
     #menu .pure-menu,
     #menu .pure-menu ul {
        border: none;
        background: transparent;
    }

    /*
    Add that light border to separate items into groups.
    */
    #menu .pure-menu ul,
    #menu .pure-menu .menu-item-divided {
        border-top: 1px solid #777;
    }
        /*
        Change color of the anchor list items on hover/focus.
       */
    .pure-menu li:focus {
        background: #777;     
    }
    .pure-menu li:hover {
	background: #777;
    }
    
    /*
    This styles the selected menu item `<li>`.
    */
    .pure-menu-selected,
    .pure-menu-heading {
        background: #1f8dd6;
    }
        /*
        This styles a link within a selected menu item `<li>`.
        */
        .pure-menu-selected li {
            color: #fff;
        }

    /*
    This styles the menu heading.
    */
    #menu .pure-menu-heading {
        font-size: 110%;
        color: #fff;
        margin: 0;
    }

/* -- Dynamic Button For Responsive Menu -------------------------------------*/


/*
`.menu-link` represents the responsive menu toggle that shows/hides on
small screens.
*/
.menu-link {
    position: fixed;
    display: block; /* show this only on small screens */
    top: 0;
    left: 0; /* "#menu width" */
    background: #000;
    background: rgba(0,0,0,0.7);
    font-size: 10px; /* change this value to increase/decrease button size */
    z-index: 10;
    width: 2em;
    height: auto;
    padding: 2.1em 1.6em;
}

    .menu-link span {
        position: relative;
        display: block;
    }

    .menu-link span,
    .menu-link span:before,
    .menu-link span:after {
        background-color: #777;
        width: 100%;
        height: 0.2em;
    }

        .menu-link span:before,
        .menu-link span:after {
            position: absolute;
            margin-top: -0.6em;
            content: " ";
        }

        .menu-link span:after {
            margin-top: 0.6em;
        }

body, html, #root, #layout, #map-container, #map {
    height: 100%;
}

/*this allows the content to take up 100% of the height to make the map responsive. The header is effectively shown because of padding on the top, and margin which pushes the layout back up*/
#layout > div.pure-u-1 {
    padding-top: 66px; /* same as header height */
    box-sizing: border-box;
    margin-top: -66px;
    height: 100%;
}

/* -- Responsive Styles (Media Queries) ------------------------------------- */

/*
Hides the menu at `48em`, and shows the menu button to expand full menu.
*/

@media screen and (min-width: 48em) {

    .header,
    .content {
        padding-left: 2em;
        padding-right: 2em;
    }

    #layout {
        padding-left: var(--menu-width); /* left col width "#menu" */
        left: 0;
    }
    #menu {
        left: var(--menu-width);
    }
 
    .menu-link {
        position: fixed;
        left: var(--menu-width);
        display: none;
    }

    #layout.active .menu-link {
        left: var(--menu-width);
    }
}

/* Additional mobile styling */

@media screen and (max-width: 428px) {
    .App-header {
        font-size: 1.3em;
	padding: 5px 0px;
	box-sizing: border-box;
	padding-left: 25px;
    }

    #layout > div.pure-u-1 {
	padding-top: 48px; /* same as header height */
	box-sizing: border-box;
	margin-top: -48px;
	height: 100%;
        left: var(--menu-width);
    }

}